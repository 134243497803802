.candidate-details-section{
    text-align: left;
    padding: 8px;
}

.success-color{
    color: #2B8000;
    font-size: 16px;
    font-weight: 600;
}

.warn-color{
    color: #BD4F00;
    font-size: 16px;
    font-weight: 600;
}