.ocrLogo {
    height: 48px;
    width: 108px;
}

.titleDivider {
    border-left: 1px solid #E0E0E0;
    height: 100px;
    background: #E0E0E0;
}
.centers-list{
    height: 116px;
    width:260px;
    overflow-y: scroll;
}
.centers-list li button{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  color:#424242;
}
.centers-list::-webkit-scrollbar {
  width: 6px;
}

.centers-list::-webkit-scrollbar-track {
  background: #ffffff;
}

.centers-list::-webkit-scrollbar-thumb {
  background-color: #21212177;
  border-radius: 10px;
  height: 40px;
  border: 1px solid #ffffff;
}
.centers-list span{
   padding-right:8px
}

.active span svg{
    color:#0076BD !important;
    transform: scale(1.4);
}
.active button{
    color:#0076BD !important;
}
.my-cambrige-link{
  color:#424242
}
.pointer-evt:hover{
  cursor: pointer;
}