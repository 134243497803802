.iu-checkpoint-text-section{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.download-checkpoint-csv-button{
    background: #fff;
    text-align: start;
    padding: 10px;
    width: 20rem;
}

.dropdown-duration{
    max-width: 20rem;

}

.wrapper{
    background: #fff;
    display: flex;
    align-items: center;
}

.back{
    margin-right: auto;
} 
