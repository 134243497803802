.minWidthStatus-col{
    width:220px;
}

.qualification-col{
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    white-space: normal;
    overflow: hidden;
    height: 100px;
    width: 200px;
}
.buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: white;
}

/*Download CSV Button*/
.download-csv-button button{
    display: block; 
}
.bulk-not-clicked{
    display: flex; 
    align-items: center;
    left:-20%;
    position:relative;

}
.bulk-click{
    display: flex; 
    align-items: center;
    

}
.download-csv button{
    min-width:170px;
}
.hideElement {
    display: none;
}
.wrap{
    display: flex;
    flex-direction: row;
}