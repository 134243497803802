body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "latoBold";
  src: local("latoBold"), url(assets/fonts/lato/Lato-Bold.woff2) format("woff2");
}
@font-face {
  font-family: "Lato";
  src: local("latoRegular"), url(assets/fonts/lato/lato-regular.woff2) format("woff2");
}

@font-face {
  font-family: "sourceSansProBold";
  src: local("sourceSansProBold"), url(assets/fonts/sourceSansPro/SourceSansPro-Bold.woff2) format("woff2");
}

@font-face {
  font-family: "sourceSansProSemiBold";
  src: local("sourceSansProSemiBold"), url(assets/fonts/sourceSansPro/SourceSansPro-SemiBold.woff2) format("woff2");
}

 @font-face { 
  /* font-family: "sourceSansProRegular";
  src: local("sourceSansProRegular"), url(assets/fonts/sourceSansPro/SourceSansPro-Regular.woff2) format("woff2"); */ 
  
  font-family: "Source Sans Pro";
  src: local("Source Sans Pro"), url(assets/fonts/sourceSansPro/SourceSansPro-Regular.woff2) format("woff2");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
