.homepage-wrapper{
    background: #F7F7F7;
}
.homepage-loader .loader{
margin-top: auto;
margin-bottom: auto;
}
.welcome-message-wrapper{
    margin-top: 148px;
    margin-left: auto;
    margin-right: auto;
}

.result-table-wrapper{
display: flex;
flex-direction: column;
padding:0px 12px
}
.download-csv-button{
    background: #fff;
    text-align:start;
}

/*table*/
.table-data-wrap .css-112hwg-MuiButtonBase-root-MuiCheckbox-root.Mui-checked:hover svg path:last-child, 
.table-data-wrap .css-112hwg-MuiButtonBase-root-MuiCheckbox-root.Mui-checked.Mui-focusVisible svg path:last-child{
    fill: #0064A6 !important;
}

/* .table-data-wrap th, .table-data-wrap td{
padding:8px;
} */

/*Notification alert*/

.notification-wrap .MuiAlert-message {
    flex-direction: column;
    align-items: start;
}
/* 
.notification-wrap .MuiAlert-action {
    align-items: center !important;
} */

.notification-wrap{
    width: 75%;
    margin: 0 auto;
    display: flex;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 25px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

  .homepage-wrapper > .container-fluid{
      max-width:1280px;
  }

@media screen and (max-width:1560px){
    .notification-wrap{
        width:84%;
    }
}