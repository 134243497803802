.typography-small-regular {
  font-size: 16px !important;
  font-family: "sourceSansProRegular", sans-serif !important;
  line-height: 20px !important; }

.typography-small-semiBold {
  font-size: 16px !important;
  font-family: "sourceSansProSemiBold", sans-serif !important;
  line-height: 20px !important; }

.typography-small-bold {
  font-size: 16px !important;
  font-family: "sourceSansProBold", sans-serif !important;
  line-height: 20px !important; }

.typography-small14-regular {
  font-size: 14px !important;
  font-family: "sourceSansProRegular", sans-serif !important;
  line-height: 18px !important; }

.typography-small14-semiBold {
  font-size: 14px !important;
  font-family: "sourceSansProSemiBold", sans-serif !important;
  line-height: 18px !important; }

.typography-small14-bold {
  font-size: 14px !important;
  font-family: "sourceSansProBold", sans-serif !important;
  line-height: 18px !important; }

.typography-medium18-lato-bold {
  font-size: 18px !important;
  font-family: "latoBold", sans-serif !important;
  line-height: 22px !important; }

.typography-medium-lato-bold {
  font-size: 16px !important;
  font-family: "latoBold", sans-serif !important;
  line-height: 20px !important; }

.typography-32-lato-bold {
  font-size: 32px !important;
  font-family: "latoBold", sans-serif !important;
  line-height: 36px !important; }

.typography-medium-lato-regular {
  font-size: 16px !important;
  font-family: "latoRegular", sans-serif !important;
  line-height: 20px !important; }

.typography-20-lato-regular {
  font-size: 20px !important;
  font-family: "latoRegular", sans-serif !important;
  line-height: 24px !important; }

.typography-24-regular {
  font-size: 24px !important;
  font-family: "sourceSansProRegular", sans-serif !important;
  line-height: 28px !important; }

.typography-24-semiBold {
  font-size: 24px !important;
  font-family: "sourceSansProSemiBold", sans-serif !important;
  line-height: 28px !important; }

.typography-24-bold {
  font-size: 24px !important;
  font-family: "sourceSansProBold", sans-serif !important;
  line-height: 28px !important; }

.dark-grey-color {
  color: #424242 !important; }

.black-color {
  color: #212121 !important; }

.blue-color {
  color: #0074BD !important; }

.MuiTypography-root.page-title {
  font-size: 32px;
  font-family: "latoBold", sans-serif;
  line-height: 36px;
  margin-bottom: 8px; }

.MuiTypography-root.page-description {
  font-size: 16px;
  font-family: "sourceSansProRegular", sans-serif;
  line-height: 15px;
  color: #424242; }

.typography-font-weight-400{
    font-weight: 400;
 }
.typography-font-weight-600{
  font-weight: 600;
}
.typography-font-weight-700{
  font-weight: 700;
}
@media screen and (max-width: 767px) {
  .MuiTypography-root.page-title {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 0; }
  .MuiTypography-root.page-description {
    font-size: 14px; } }
