.checkpoint-filter-wrapper {
    width: 296px !important;
    margin: 4px 0px 0px -8px;
}

.candidate-placeholder {
    height: 44px;
}

.candidate-placeholder::placeholder {
    white-space: break-spaces;
    font-size: 14px;
    padding-bottom: 20px;
}

.button-container button {
    width: 136px;
}

.unit-select-dropdown{
    width:268px;
}
.unit-list-item{
    /* padding:12px 4px !important; */
}
.unit-list-item:first-child{
    padding: 12px 20px 12px 16px;
}

