.eo-checkpoint-wrapper .MuiAlert-message{
    display: flex;
    flex-direction: column;
    text-align: left;
}
.eo-checkpoint-wrapper .loader svg{
        transform: scale(0.5)
    }
.eo-checkpoint-wrapper{
        margin:8px
    }
.button1
{
    padding: 12px;
    background-color: #dee2e6;
    color: black;
}