.filter-wrapper {
    width: 296px !important;
}

.candidate-placeholder {
    height: 44px;
}

.candidate-placeholder::placeholder {
    white-space: break-spaces;
    font-size: 14px;
    padding-bottom: 20px;
}

.button-container button {
    width: 136px;
}

.hideElement {
    display: none;
}
.candidate-search-bar div{
    background-color: #fff !important;
}

.candidate-search-bar button:hover{
cursor: default;
}

[data-title]:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
}
[data-title]:after {
    content: attr(data-title);
    inline-size: 160px;
    padding:8px;
    border-radius: 6px;
    background-color: #353935;
    color: #fff;
    font-size: 14px;
    position: absolute;
    bottom: 20%;
    left: 50%;
    overflow-wrap: break-word;
    opacity: 0.3;
    visibility: hidden;
}
[data-title] {
    /* position: relative; */
}